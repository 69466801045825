import { useLocation } from 'react-router-dom';
import { createSortString, extractAssetId, makeRoute, parseRoute, parseSortString } from './routeHelper';
import { useRouteState, useUpdateRoute } from './routeHooks';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import {
    assetTableApplyFilterChanges,
    assetTableSearchChanged,
    assetTableSortChanges,
    changeAssetDetailsTab,
    getAssetDetailTab,
    getAssetTableSortColumn,
    getAssetTableSortDir,
    getSearchString,
    getShowActiveAssets,
    getShowArchivedAssets,
    getShowOnlyAssetsWithoutDataSource,
    openEasyOnboardingDialog,
    setSelectedAssetId,
} from '../reducers/assets/assetsSlice';
import { Action } from '@reduxjs/toolkit';
import { AssetsListDetailTab } from '../containers/assets/details/types';

const RouteUpdater = () => {
    const dispatch = useAppDispatch();
    const { search, pathname } = useLocation();

    const selectedAssetId = useAppSelector((state) => state.assets.selectedAssetId);
    const currentSearch = useAppSelector(getSearchString);
    const selectedSortBy = useAppSelector(getAssetTableSortColumn);
    const selectedSortDir = useAppSelector(getAssetTableSortDir);
    const showActiveAssets = useAppSelector(getShowActiveAssets);
    const showArchivedAssets = useAppSelector(getShowArchivedAssets);
    let selectedStatus = 'all';
    if (showActiveAssets && !showArchivedAssets) {
        selectedStatus = 'active';
    } else if (!showActiveAssets && showArchivedAssets) {
        selectedStatus = 'archived';
    } else if (!showActiveAssets && !showArchivedAssets) {
        selectedStatus = 'none';
    }
    const selectedWithoutDataSource = useAppSelector(getShowOnlyAssetsWithoutDataSource);
    const currentShowEasyOnboardingDialog = useAppSelector((state) => state.assets.easyOnboardingDialog.show);

    const selectedTab = useAppSelector(getAssetDetailTab);

    // Parse initial route or after it has changed by browser navigation or user input
    useRouteState(() => {
        const dispatchQue: Action[] = [];

        const assetId = extractAssetId(pathname);
        const routeSearchParams = parseRoute(search);
        const { status, tab, withoutDataSource, sort, q, createAssetModal } = routeSearchParams;

        if (q && q !== currentSearch) {
            dispatchQue.push(assetTableSearchChanged(q));
        }

        const parsedSort = sort ? parseSortString(sort) : undefined;

        if (parsedSort && (parsedSort.sortBy !== selectedSortBy || parsedSort.sortDir !== selectedSortDir)) {
            dispatchQue.push(assetTableSortChanges(parsedSort));
        }

        if (tab && tab !== selectedTab) {
            if (tab === 'details') {
                dispatchQue.push(changeAssetDetailsTab(AssetsListDetailTab.ASSET_DETAILS));
            } else if (tab === 'group') {
                dispatchQue.push(changeAssetDetailsTab(AssetsListDetailTab.TAG_LIST));
            } else if (tab === 'device') {
                dispatchQue.push(changeAssetDetailsTab(AssetsListDetailTab.DEVICE_LIST));
            }
        }

        if ((status && status !== selectedStatus) || (withoutDataSource === 'true') !== selectedWithoutDataSource) {
            const showOnlyAssetsWithoutDataSources = withoutDataSource === 'true';
            const showActiveAssets = status === 'all' || status === 'active';
            const showArchivedAssets = status === 'all' || status === 'archived';

            dispatchQue.push(
                assetTableApplyFilterChanges({
                    showOnlyAssetsWithoutDataSources,
                    showActiveAssets,
                    showArchivedAssets,
                })
            );
        }

        if (createAssetModal === 'show' && !currentShowEasyOnboardingDialog) {
            dispatchQue.push(openEasyOnboardingDialog());
        }

        if (assetId && assetId !== selectedAssetId) {
            dispatchQue.push(setSelectedAssetId(assetId));
        }

        dispatchQue.forEach((action: Action) => dispatch(action));
    });

    // Update route whenever an observed store prop changes.
    // The key of the RouteState is the search param key in the URL.
    const newRoute = makeRoute({
        status: selectedStatus,
        tab: selectedTab,
        withoutDataSource: selectedWithoutDataSource ? 'true' : undefined,
        sort: createSortString({ sortBy: selectedSortBy, sortDir: selectedSortDir }),
        q: encodeURIComponent(currentSearch),
        createAssetModal: currentShowEasyOnboardingDialog ? 'show' : undefined,
        // Easy onboarding. After initializing the dialog we reset the url at the moment
        // We might want to properly handle this in the future. This would require tracking the onboarding steps
        // in Redux
        step: undefined,
        vehicle_type: undefined,
        brand: undefined,
        device_type: undefined,
    });

    useUpdateRoute({ newSubResource: selectedAssetId ?? undefined, newSearch: newRoute });

    return null;
};

export default RouteUpdater;
