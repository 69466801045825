import { Asset, AssociatedDevice } from '../../components/assets/types';
import { SelectedTag } from '../../components/assets/details/types';
import { AssetsListDetailTab } from '../../containers/assets/details/types';
import type { SortDirectionType } from '@rio-cloud/rio-uikit/SortDirection';

export const fetchStatus = {
    IDLE: 'idle',
    PENDING: 'pending',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
} as const;

export type FetchStatus = (typeof fetchStatus)[keyof typeof fetchStatus];

export interface AssetsState {
    assets: Asset[];
    assetsFetchStatus: FetchStatus;
    selectedAssetId: string | null;
    fetching: boolean;
    tableViewType: string;
    selectedAsset: Asset | null;
    selectedAssociatedDevices: AssociatedDevice[];
    selectedDevicesFetching: boolean;
    associatedDevices: { [key: string]: AssociatedDevice[] };
    devicesFetchStatus: FetchStatus;
    assetDeleting: boolean;
    displayDeleteDialog: boolean;
    deletionSuccessful: boolean;
    deletionErrorMessageCode: string | null;
    assetUpdating: boolean;
    assetUpdateSuccessful: boolean;
    assetUpdateFailed: boolean;
    assetUpdateErrorCode: string | null;
    searchString: string;
    filters: {
        showOnlyAssetsWithoutDataSource: boolean;
        showActiveAssets: boolean;
        showArchivedAssets: boolean;
    };
    showFilterOptionsDialog: boolean;
    sortBy: string | null;
    sortDir: SortDirectionType;
    tags: {
        updating: boolean;
        removals: {
            lastSuccess: SelectedTag | null;
            lastFailure: SelectedTag | null;
        };
        additions: {
            lastSuccess: SelectedTag | null;
            lastFailure: SelectedTag | null;
        };
    };
    easyOnboardingDialog: {
        show: boolean;
        selectedAssetId: string | undefined;
    };
    isAssetsReloadImminent: boolean;
    currentAssetDetailsTab: AssetsListDetailTab;
}
