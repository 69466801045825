import React from 'react';
import Tag from '@rio-cloud/rio-uikit/Tag';
import { AssetTagsProps } from './types';

export const AssetTags = (props: AssetTagsProps) => (
    <ul className="list-inline margin-bottom-0">
        {props.tags
            .filter((t) => props.assetTags.includes(t.id))
            .sort((t1, t2) => t1.name.toLowerCase().localeCompare(t2.name.toLowerCase()))
            .map((t) => {
                return (
                    <li key={t.id} className="margin-1">
                        <Tag size={'small'}> {t.name} </Tag>
                    </li>
                );
            })}
    </ul>
);
