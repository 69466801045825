import { connect } from 'react-redux';
import { AssetsListDetail } from '../../../components/assets/details/AssetsListDetail';
import { getTags } from '../../../reducers/tags/tagsSlice';
import {
    deleteAsset,
    fetchSelectedDevices,
    updateAsset,
    updateAssetTags,
} from '../../../actions/assets/Assets.actions';
import {
    assetDeleteDialogClose,
    assetDeleteDialogOpen,
    changeAssetDetailsTab,
    getAssetDeleting,
    getAssetDetailTab,
    getAssetTagsUpdating,
    getAssetUpdating,
    getAssociatedDevices,
    getDeletionErrorMessageCode,
    getDeletionSuccessful,
    getDisplayDeleteDialog,
    resetAssetDeletionState,
    resetAssetUpdateState,
} from '../../../reducers/assets/assetsSlice';
import { Asset, AssociatedDevice, Masterdata } from '../../../components/assets/types';
import { SelectedTag } from '../../../components/assets/details/types';
import { createAssetTags } from '../../../actions/tags/Tags.actions';
import { AssetsThunkDispatch } from '../../../actions/assets/types';
import { injectIntl } from 'react-intl';
import {
    AssetListDetailOwnProperties,
    AssetListDetailPropertiesFromState,
    AssetListDetailPropsFromDispatch,
    AssetsListDetailTab,
} from './types';
import { updateMasterdata } from '../../../actions/assets/Masterdata.actions';
import { RootState } from '../../../../../configuration/setup/store';

export type AssetListDetailProperties = AssetListDetailPropertiesFromState &
    AssetListDetailPropsFromDispatch &
    AssetListDetailOwnProperties;

function mapDispatchToProps(dispatch: AssetsThunkDispatch): AssetListDetailPropsFromDispatch {
    return {
        fetchSelectedDevices: (assetId: string, associatedDevices: { [key: string]: AssociatedDevice[] }) =>
            dispatch(fetchSelectedDevices(assetId, associatedDevices)),
        updateAssetTags: (asset: Asset, tags: SelectedTag[]) => dispatch(updateAssetTags(asset, tags)),
        createAssetTags: (assetTags: SelectedTag[], assetToUpdate?: Asset) =>
            dispatch(createAssetTags(assetTags, assetToUpdate)),
        updateAsset: (originalAsset: Asset, updatedAsset: Asset) => dispatch(updateAsset(originalAsset, updatedAsset)),
        updateMasterdata: (asset: Asset, originalMasterdata: Masterdata | null, updatedMasterdata: Masterdata) =>
            dispatch(updateMasterdata(asset, originalMasterdata, updatedMasterdata)),
        openDeleteDialog: () => dispatch(assetDeleteDialogOpen()),
        closeDeleteDialog: () => dispatch(assetDeleteDialogClose()),
        deleteAsset: (asset: Asset) => dispatch(deleteAsset(asset)),
        changeAssetDetailsTab: (selectedTab: AssetsListDetailTab) => dispatch(changeAssetDetailsTab(selectedTab)),
        resetAssetUpdateState: () => dispatch(resetAssetUpdateState()),
        resetAssetDeletionState: () => dispatch(resetAssetDeletionState()),
    };
}

function mapStateToProps(state: RootState): AssetListDetailPropertiesFromState {
    return {
        associatedDevices: getAssociatedDevices(state),
        assetUpdating: getAssetUpdating(state),
        assetDeleting: getAssetDeleting(state),
        displayDeleteDialog: getDisplayDeleteDialog(state),
        deletionSuccessful: getDeletionSuccessful(state),
        deletionErrorMessageCode: getDeletionErrorMessageCode(state),
        availableTags: getTags(state),
        assetTagsUpdating: getAssetTagsUpdating(state),
        currentAssetDetailsTab: getAssetDetailTab(state),
    };
}

const AssetsListDetailContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssetsListDetail));
export default AssetsListDetailContainer;
