import { TagsThunkAction } from './types';

import { getAccessToken } from '../../../../configuration/tokenHandling/tokenHandlingSlice';
import { config } from '../../../../config';
import { Asset, Tag } from '../../components/assets/types';
import { updateAssetTags } from '../assets/Assets.actions';
import { SelectedTag } from '../../components/assets/details/types';
import {
    getTagsFetchStatus,
    tagCreationFailed,
    tagCreationStarted,
    tagCreationSuccessful,
    tagsLoaded,
    tagsLoadedFailed,
    tagsStartLoading,
} from '../../reducers/tags/tagsSlice';
import { AccessToken } from '../../../../configuration/types';
import { fetchStatus } from '../../reducers/assets/types';

export function fetchTags(accountId: string | null, options?: { force: true }): TagsThunkAction<Promise<void>> {
    return async (dispatch, getState) => {
        const state = getState();
        const tagsFetchStatus = getTagsFetchStatus(state);
        if (options?.force || tagsFetchStatus === fetchStatus.IDLE) {
            dispatch(tagsStartLoading());

            await fetchTagsPaginated(getAccessToken(state), accountId)
                .then((result: Tag[]) => dispatch(tagsLoaded(result)))
                .catch(() => dispatch(tagsLoadedFailed()));
        }
    };
}

export const fetchTagsPaginated = async (accessToken: AccessToken, accountId: string | null): Promise<Tag[]> => {
    const url = new URL(`${config.backend.tagService}/tags`);

    if (null !== accountId) {
        url.searchParams.append('account_id', accountId);
    }

    let nextLink = url.toString();
    let tags: Tag[] = [];

    while (nextLink !== null && nextLink !== undefined) {
        const response = await fetch(nextLink, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        const contentType = response.headers.get('content-type');

        if (response.status === 200 && contentType && contentType.indexOf('application/json') !== -1) {
            const json = await response.json();
            nextLink = json._links?.next?.href;
            tags = tags.concat(json.items as Tag[]);
        } else {
            return Promise.reject();
        }
    }
    return tags;
};

export function createAssetTags(assetTags: SelectedTag[], assetToUpdate?: Asset): TagsThunkAction<Promise<void>> {
    return async (dispatch, getState) => {
        const state = getState();
        for (const assetTag of assetTags) {
            dispatch(tagCreationStarted());
            const url = new URL(`${config.backend.tagService}/tags/${assetTag.id}`);
            const tagsEndpoint = url.toString();
            const tag: Tag = {
                id: assetTag.id,
                type: assetTag.type,
                name: assetTag.name,
                account_id: assetTag.account_id,
            };
            const response = await fetch(tagsEndpoint, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${getAccessToken(state)}`,
                    'Content-Type': 'application/json',
                    'If-None-Match': '*',
                },
                body: JSON.stringify(tag),
            });
            if (response.ok) {
                dispatch(
                    tagCreationSuccessful({
                        tag: assetTag,
                        asset: assetToUpdate,
                    })
                );
                if (assetToUpdate) {
                    await dispatch(updateAssetTags(assetToUpdate, [assetTag]));
                }
            } else {
                dispatch(
                    tagCreationFailed({
                        tag: assetTag,
                    })
                );
            }
        }
    };
}
