import { FunctionComponent, JSX } from 'react';
import { useAppDispatch, useAppSelector, useAssetsThunkDispatch } from '../../../../../configuration/setup/hooks';
import SimpleDialog from '@rio-cloud/rio-uikit/SimpleDialog';
import { fetchAssets, fetchDevices } from '../../../actions/assets/Assets.actions';
import { closeEasyOnboardingDialog, setIsAssetReloadImminent } from '../../../reducers/assets/assetsSlice';
import { FormStep } from './EasyOnboardingDialog';
import { FormattedMessage } from 'react-intl';
import { AssetTypeTile, BrandTile, TelematicsTile } from './configuration/types';

interface SimpleDialogWrapperProps {
    formStep: FormStep;
    selectedAssetType: AssetTypeTile | undefined;
    selectedBrand: BrandTile | undefined;
    selectedTelematics: TelematicsTile | undefined;
    dialogContent: JSX.Element;
}

export const SimpleDialogWrapper: FunctionComponent<SimpleDialogWrapperProps> = (props) => {
    const showDialog = useAppSelector((state) => state.assets.easyOnboardingDialog.show);
    const dispatch = useAppDispatch();
    const assetsThunkDispatch = useAssetsThunkDispatch();
    const getTitle = () => {
        switch (props.formStep) {
            case FormStep.selectAssetType:
                return <FormattedMessage id={'assets.assets.create-new-dialog.type.message'} />;
            case FormStep.selectBrand:
                return props.selectedAssetType ? (
                    <FormattedMessage
                        id={`assets.assets.create-new-dialog.brand.message.${props.selectedAssetType.assetType}`}
                    />
                ) : (
                    <FormattedMessage id={'assets.assets.create-new-dialog.brand.message.fallback'} />
                );
            case FormStep.selectTelematics:
                return props.selectedAssetType ? (
                    <FormattedMessage
                        id={`assets.assets.create-new-dialog.telematics.message.${props.selectedAssetType.assetType}`}
                    />
                ) : (
                    <FormattedMessage id={'assets.assets.create-new-dialog.telematics.message.fallback'} />
                );
            case FormStep.connector:
                return <FormattedMessage id="assets.assets.create-new-dialog.connector.message" />;
            default:
                return '';
        }
    };

    return (
        <SimpleDialog
            show={showDialog}
            bodyClassName={'padding-0 bg-lightest'}
            title={getTitle()}
            content={props.dialogContent}
            onClose={() => {
                dispatch(closeEasyOnboardingDialog());
                dispatch(setIsAssetReloadImminent(true)); // gets reset with fetchDevices()
                setTimeout(
                    () =>
                        assetsThunkDispatch(fetchAssets({ force: true })).then(() =>
                            assetsThunkDispatch(fetchDevices({ force: true }))
                        ),
                    2000
                );
            }}
        />
    );
};
