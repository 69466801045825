function enableDebugLogs() {
    return localStorage.getItem('enableDebugLogs') === 'true';
}

export const trace = enableDebugLogs()
    ? (...args) => {
          if (import.meta.env.MODE !== 'test') {
              console.log('[debug]', ...args);
          }
      }
    : () => {};
