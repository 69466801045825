import React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import CustomState from '@rio-cloud/rio-uikit/CustomState';
import TagManager from '@rio-cloud/rio-uikit/TagManager';
import { SelectedTag, SelectedTagStatus, TagManagerTagProp, USER_TAG_TYPE } from './types';
import { Tag } from '../types';
import { useAppSelector } from '../../../../../configuration/setup/hooks';
import { isTaggingServiceAvailable } from '../../../reducers/tags/tagsSlice';
import { v4 as uuidv4 } from 'uuid';

interface Props {
    accountId: string | undefined | null;
    assetTags: string[] | undefined;
    onTagListChange: (selectedTags: SelectedTag[]) => void;
    availableTags: Tag[];
    useCustomTags: boolean;
    customMessage: string;
}

const getFormattedMessagePlaceholder = (intl: IntlShape) =>
    intl.formatMessage({ id: 'assets.assets.asset-list-detail.tag.placeholder' });

const getFormattedMessageCustomTagPlaceholder = (intl: IntlShape) =>
    intl.formatMessage({ id: 'assets.assets.asset-list-detail.tag.customTagPlaceholder' });

const getFormattedMessageDropdownSeparatorText = (intl: IntlShape) =>
    intl.formatMessage({ id: 'assets.assets.asset-list-detail.tag.dropdownSeparatorText' });

const getFormattedMessageNoItemMessage = (intl: IntlShape) =>
    intl.formatMessage({ id: 'assets.assets.asset-list-detail.tag.noItemMessage' });

const getCurrentTagsFromAvailableTags = (props: Props): SelectedTag[] =>
    props.availableTags
        .filter((tag: Tag) => props.assetTags && props.assetTags.includes(tag.id))
        .map((tag: Tag) => ({
            ...tag,
            status: SelectedTagStatus.unchanged,
        }));

const onTagListChange = (props: Props) => (tagManagerTagsChanged: TagManagerTagProp[]) => {
    const currentTags = getCurrentTagsFromAvailableTags(props);
    const accountId = props.accountId;
    if (accountId) {
        const tagsToAdd: SelectedTag[] = tagManagerTagsChanged
            .filter((tagChanged: TagManagerTagProp) => tagChanged.toAdd === true)
            .flatMap((tagChanged: TagManagerTagProp) => {
                const presentTag = props.availableTags.find((tag: Tag) => tag.name === tagChanged.label);
                if (presentTag !== undefined) {
                    return {
                        ...presentTag,
                        status: SelectedTagStatus.added,
                    };
                } else {
                    return {
                        id: uuidv4(),
                        type: USER_TAG_TYPE,
                        name: tagChanged.label!,
                        account_id: accountId,
                        status: SelectedTagStatus.created,
                    };
                }
            });

        const deleteTags: SelectedTag[] = tagManagerTagsChanged
            .filter((tagChanged: TagManagerTagProp) => tagChanged.toRemove === true)
            .flatMap((tagChanged: TagManagerTagProp) => {
                const presentTag = currentTags.find((tag: Tag) => tag.name === tagChanged.label);
                if (presentTag !== undefined) {
                    return {
                        ...presentTag,
                        status: SelectedTagStatus.removed,
                    };
                } else {
                    return [];
                }
            });
        const previousCurrentTags = currentTags.filter(
            (currentTag) => ![...tagsToAdd, ...deleteTags].map((tag) => tag.name).includes(currentTag.name)
        );
        props.onTagListChange([...tagsToAdd, ...deleteTags, ...previousCurrentTags]);
    }
};

export const TagManagerWrapper = React.memo((props: Props) => {
    const taggingServiceIsAvailable = useAppSelector(isTaggingServiceAvailable);

    const intl = useIntl();

    const currentTags = getCurrentTagsFromAvailableTags(props);

    return (
        <div>
            {taggingServiceIsAvailable && (
                <CustomState
                    headline={<FormattedMessage id={'assets.assets.asset.tags'} />}
                    message={
                        <div data-cy="tag-list">
                            <div className={'margin-bottom-20'}>
                                <FormattedMessage id={props.customMessage} />
                            </div>
                            <TagManager
                                tagList={currentTags
                                    .filter((tag) => tag.status !== SelectedTagStatus.removed)
                                    .map((tag) => ({ label: tag.name }))}
                                tagSuggestions={props.availableTags.map((tag: Tag) => ({ label: tag.name }))}
                                onTagListChange={onTagListChange(props)}
                                placeholder={getFormattedMessagePlaceholder(intl)}
                                customTagPlaceholder={getFormattedMessageCustomTagPlaceholder(intl)}
                                dropdownSeparatorText={getFormattedMessageDropdownSeparatorText(intl)}
                                noItemMessage={getFormattedMessageNoItemMessage(intl)}
                                useCustomTags={props.useCustomTags}
                                showInput={props.useCustomTags}
                            />
                        </div>
                    }
                    icons={[
                        {
                            name: 'rioglyph rioglyph-van',
                            className: 'text-size-200pct',
                        },
                        {
                            name: 'rioglyph rioglyph-truck',
                            className: 'text-size-300pct',
                        },
                        {
                            name: 'rioglyph rioglyph-driver',
                            className: 'text-size-300pct',
                        },
                        {
                            name: 'rioglyph rioglyph-trailer',
                            className: 'text-size-200pct',
                        },
                    ]}
                    outerClassName={'margin-bottom-15 bg-highlight-decent'}
                    condensed={true}
                />
            )}
        </div>
    );
});
