import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import { CsvExport } from './CsvExport';
import ToggleButton from '@rio-cloud/rio-uikit/ToggleButton';
import { FormattedMessage } from 'react-intl';
import TableSearch from '@rio-cloud/rio-uikit/TableSearch';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import {
    assetTableSearchChanged,
    assetTableViewTypeChanges,
    filterOptionsDialogOpen,
    getIsAssetsReloadImminent,
    getSearchString,
    getShowFilterOptionsDialog,
    openEasyOnboardingDialog,
} from '../../reducers/assets/assetsSlice';
import { Asset } from './types';
import {
    GAEventName,
    GAEventTrigger,
    GAFlowName,
    useGoogleAnalytics,
} from '../../utils/googleAnalytics4AssetOnboardingFlow';

interface Props {
    filterOptionsDialog: React.ReactNode;
    assets: Asset[];
}

export const AssetsListToolbar = (props: Props) => {
    const { filterOptionsDialog, assets } = props;

    const dispatch = useAppDispatch();

    const searchString = useAppSelector(getSearchString);
    const showFilterOptionsDialog = useAppSelector(getShowFilterOptionsDialog);
    const isAssetsReloadImminent = useAppSelector(getIsAssetsReloadImminent);

    const searchInputRef = useRef<HTMLInputElement>(null);

    const { dataLayerPush } = useGoogleAnalytics();

    useEffect(() => {
        searchInputRef.current?.focus();
    }, []);

    // component logic
    const handleSearchChange = (searchValue: string) => {
        dispatch(assetTableSearchChanged(searchValue));
    };

    const renderButtonEasyOnboarding = (
        <div>
            <button
                className="btn btn-primary"
                type="button"
                id="addAsset-new"
                data-cy="add-asset-new"
                onClick={() => {
                    dataLayerPush({
                        trigger: GAEventTrigger.click,
                        event: GAEventName.selectAddAsset,
                        element_name: 'add_asset_toolbar_button',
                        flow_name: GAFlowName.easyOnboardingFlow,
                    });
                    dispatch(openEasyOnboardingDialog());
                }}
            >
                <span className="rioglyph rioglyph-plus" />
                <FormattedMessage id={'assets.assets.create'} />
            </button>
        </div>
    );

    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <div className="table-toolbar-column">{renderButtonEasyOnboarding}</div>
                    {assets.length > 0 && (
                        <div className="table-toolbar-column">
                            <CsvExport assets={assets} />
                        </div>
                    )}
                    {isAssetsReloadImminent && (
                        <div className="table-toolbar-column">
                            <button className="btn btn-muted btn-loading btn-icon-only" />
                        </div>
                    )}
                </div>
                <div className="table-toolbar-group-right">
                    <div className="table-toolbar-column justify-content-center">
                        <ToggleButton
                            active={showFilterOptionsDialog}
                            onClick={() => dispatch(filterOptionsDialogOpen())}
                            data-cy="options-button"
                        >
                            <span className={'rioglyph rioglyph-filter'} />
                            <FormattedMessage id={'assets.assets.options'} />
                        </ToggleButton>
                        {filterOptionsDialog}
                    </div>
                    {(assets.length > 0 || searchString !== '') && (
                        <>
                            <div className="table-toolbar-column">
                                <FormattedMessage id={'assets.assets.search-placeholder'}>
                                    {(placeholder) => (
                                        <TableSearch
                                            value={searchString}
                                            inputRef={searchInputRef}
                                            onChange={handleSearchChange}
                                            placeholder={placeholder}
                                            data-cy="asset-table-search-input"
                                        />
                                    )}
                                </FormattedMessage>
                            </div>
                            <div className="table-toolbar-column">
                                <div className="btn-toolbar">
                                    <TableViewToggles
                                        onViewTypeChange={(viewType) => dispatch(assetTableViewTypeChanges(viewType))}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </TableToolbar>
    );
};
