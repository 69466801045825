import SortDirection, { type SortDirectionType } from '@rio-cloud/rio-uikit/SortDirection';
import qs, { type IParseOptions, type IStringifyOptions } from 'qs';

export const STRINGIFY_OPTIONS: IStringifyOptions = {
    // required to stringify arrays into comma separated strings
    arrayFormat: 'comma',
    // don't use array indices
    indices: false,
    // don't encode the entire string as it will be done individually for certain params
    encode: false,
    // required to remove empty params
    skipNulls: true,
};

export const PARSE_OPTIONS: IParseOptions = {
    // required to parse comma separated string into array
    comma: true,
};

export type PathRouteState = {
    assetId: string | undefined;
};

export type SearchRouteState = {
    status: string | undefined;
    tab: string | undefined;
    withoutDataSource: string | undefined;
    sort: string | undefined;
    q: string | undefined;
    createAssetModal: string | undefined;
    device_type: string | undefined;
    vehicle_type: string | undefined;
    step: string | undefined;
    brand: string | undefined;
};

export const extractAssetId = (locationPath: string): string | undefined => {
    const match = locationPath.match(/\/assets\/([^/]+)$/);
    return match?.[1];
};

export const updateAssetId = (path: string, newId: string | undefined): string => {
    const currentId = extractAssetId(path);
    if (newId === undefined) return '/assets';
    if (currentId === newId) return path;

    return currentId ? path.replace(currentId, newId) : `/assets/${newId}`;
};

export const parseRoute = (locationSearch: string): SearchRouteState => {
    const decodedSearch = decodeURIComponent(locationSearch.replace('?', ''));
    const { status, tab, withoutDataSource, sort, q, createAssetModal, device_type, vehicle_type, step, brand } =
        qs.parse(decodedSearch, PARSE_OPTIONS);
    return {
        status,
        tab,
        withoutDataSource,
        sort,
        q,
        createAssetModal,
        device_type,
        vehicle_type,
        step,
        brand,
    } as SearchRouteState;
};

const filterOutEmptyStrings = (state: SearchRouteState): SearchRouteState => {
    const copy = { ...state };
    for (const key in copy) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (Object.hasOwn(copy, key) && copy[key] === '') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            copy[key] = undefined;
        }
    }
    return copy;
};

export const makeRoute = (searchParams: SearchRouteState): string => {
    const nonEmptySearchParams = filterOutEmptyStrings(searchParams);
    const queryParams = qs.stringify(nonEmptySearchParams, STRINGIFY_OPTIONS);
    const searchFragment = queryParams && `?${queryParams}`;
    return encodeURI(searchFragment);
};

export const parseSortString = (sort: string): { sortBy: string; sortDir: SortDirectionType } => {
    const direction = sort.startsWith('-') ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    const field = sort.substring(1);

    return {
        sortBy: field,
        sortDir: direction,
    };
};

export const createSortString = (config: { sortBy: string | null; sortDir: SortDirectionType }): string | undefined => {
    const prefix = config.sortDir === SortDirection.ASCENDING ? '+' : '-';
    return config.sortBy ? `${prefix}${config.sortBy}` : undefined;
};
