import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import useAfterMount from '@rio-cloud/rio-uikit/useAfterMount';
import { updateAssetId } from './routeHelper';

export const useRouteState = (callback: () => void) => {
    const { pathname, search } = useLocation();
    const searchRef = useRef('');
    const pathnameRef = useRef('');

    useEffect(() => {
        if (searchRef.current === search && pathnameRef.current === pathname) {
            return;
        }
        callback();
        searchRef.current = search;
        pathnameRef.current = pathname;
    }, [pathname, search, callback]);
};

export const useUpdateRoute = ({
    newSubResource,
    newSearch,
}: {
    newSubResource: string | undefined;
    newSearch: string;
}) => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const newPathname = updateAssetId(pathname, newSubResource);

    useAfterMount(() => {
        let skip = false;
        if (isInitialLoad && newPathname + newSearch === '/assets?status=all&tab=details') {
            skip = true;
            setIsInitialLoad(false);
        }
        if (skip) {
            return;
        }
        if (pathname !== newPathname || search !== newSearch) {
            navigate(newPathname + newSearch);
        }
    }, [newPathname, newSearch, isInitialLoad, setIsInitialLoad]);
};
