import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import { isNullOrEmpty, trimValue } from '../../../actions/helper';
import { AssetListDetailProperties } from '../../../containers/assets/details/AssetsListDetail.container';
import { TagManagerWrapper } from './TagManagerWrapper';
import { AssetsListDetailTab } from '../../../containers/assets/details/types';
import { closableSuccessNotification } from '../ClickableNotifications';
import {
    endDateIsSameOrAfterStartDateOrNull,
    isAssetIdentificationValid,
    numberHasChanged,
    stringHasChanged,
} from './assetDetailsHelper';
import { Asset, AssetStatus, IdentificationType } from '../types';
import { ArchiveAssetConfirmationDialog } from './ArchiveAssetConfirmationDialog';
import { AssetDetails, DATE_FORMAT } from './AssetDetails';
import { DeviceList } from './DeviceList';
import { SidebarFooter } from './SidebarFooter';
import { SelectedTag, SelectedTagStatus } from './types';

const SIDEBAR_BREAKPOINT = 1440;

export type AssetsListDetailsProps = AssetListDetailProperties & WrappedComponentProps;

export const AssetsListDetail = (props: AssetsListDetailsProps) => {
    const {
        availableTags,
        asset,
        assetDeleting,
        assetTagsUpdating,
        assetUpdating,
        changeAssetDetailsTab,
        currentAssetDetailsTab,
        closeDeleteDialog,
        createAssetTags,
        deleteAsset,
        deletionErrorMessageCode,
        deletionSuccessful,
        displayDeleteDialog,
        fetchSelectedDevices,
        associatedDevices,
        onClose,
        openDeleteDialog,
        toggleConfirmDialog,
        toggleUnsavedAssetChanges,
        updateAsset,
        updateMasterdata,
        updateAssetTags,
        resetAssetDeletionState,
    } = props;

    const [currentAssetName, setCurrentAssetName] = useState<string>(asset.name);
    const [currentAssetStatus, setCurrentAssetStatus] = useState<AssetStatus>(asset.status);
    const [currentAssetIdentification, setCurrentAssetIdentification] = useState(asset.identification);
    const [currentAssetBrand, setCurrentAssetBrand] = useState(asset.brand);
    const [currentAssetLicensePlate, setCurrentAssetLicensePlate] = useState<string | null>(asset.license_plate);
    const [currentAssetLicensePlateCountryCode, setCurrentAssetLicensePlateCountryCode] = useState<string | null>(
        asset.license_plate_country_code
    );
    const [identificationHasError, setIdentificationHasError] = useState(false);
    const [unsavedTags, setUnsavedTags] = useState<SelectedTag[]>([]);
    const [showArchiveAssetConfirmationDialog, setShowArchiveAssetConfirmationDialog] = useState<boolean>(false);
    // Masterdata
    const [currentMasterdataAdr, setMasterdataAdr] = useState(asset.masterdata?.adr);
    const [currentMasterdataAirFreightSuitability, setMasterdataAirFreightSuitability] = useState(
        asset.masterdata?.air_freight_suitability
    );
    const [currentMasterdataComment, setMasterdataComment] = useState(asset.masterdata?.comment);
    const [currentMasterdataCustomsBond, setMasterdataCustomsBond] = useState(asset.masterdata?.customs_bond);
    const [currentMasterdataCostCenter, setMasterdataCostCenter] = useState(asset.masterdata?.cost_center);
    const [currentMasterdataFuelType, setMasterdataFuelType] = useState(asset.masterdata?.fuel_type);
    const [currentMasterdataLeasingRate, setMasterdataLeasingRate] = useState(
        asset.masterdata?.ownership?.contract_rate
    );
    const [currentMasterdataNumberOfAxes, setMasterdataNumberOfAxes] = useState(asset.masterdata?.number_of_axes);
    const [currentMasterdataVehicleModel, setMasterdataVehicleModel] = useState(asset.masterdata?.vehicle_model);
    const [currentMasterdataEngineType, setMasterdataEngineType] = useState(asset.masterdata?.engine_type);
    const [currentMasterdataOwnershipEndDate, setMasterdataOwnershipEndDate] = useState(
        asset.masterdata?.ownership?.ownership_end_at ?? undefined
    );
    const [currentMasterdataOwnershipEndDateChangeJustTriggered, setMasterdataOwnershipEndDateChangeJustTriggered] =
        useState(false);
    const [currentMasterdataOwnershipStartDate, setMasterdataOwnershipStartDate] = useState(
        asset.masterdata?.ownership?.ownership_start_at ?? undefined
    );
    const [currentMasterdataOwnershipTermInMonths, setMasterdataOwnershipTermInMonths] = useState(
        asset.masterdata?.ownership?.contract_duration_in_months
    );
    const [
        currentMasterdataOwnershipTermInMonthsChangeJustTriggered,
        setMasterdataOwnershipTermInMonthsChangeJustTriggered,
    ] = useState(false);
    const [currentMasterdataOwnershipType, setMasterdataOwnershipType] = useState(asset.masterdata?.ownership?.type);
    const [currentMasterdataReference, setMasterdataReference] = useState(asset.masterdata?.ownership?.reference);
    const [currentMasterdataRegisteredFirstAt, setMasterdataRegisteredFirstAt] = useState(
        asset.masterdata?.ownership?.registered_first_at ?? undefined
    );
    const [currentMasterdataTires, setMasterdataTires] = useState(asset.masterdata?.tires);

    const hasUnsavedChanges = useCallback(() => {
        const hasLicensePlateChanged = stringHasChanged(currentAssetLicensePlate, asset.license_plate);
        const hasLicensePlateCountryCodeChanged = stringHasChanged(
            currentAssetLicensePlateCountryCode,
            asset.license_plate_country_code
        );
        const hasNameChanged = stringHasChanged(currentAssetName, asset.name);
        const hasIdentificationChanged = stringHasChanged(currentAssetIdentification, asset.identification);
        const hasTagsChanged = unsavedTags.length > 0;
        const hasStatusChanged = stringHasChanged(currentAssetStatus, asset.status);

        const hasMasterdataAdrChanged = stringHasChanged(currentMasterdataAdr, asset.masterdata?.adr);
        const hasMasterdataAirFreightSuitabilityChanged = stringHasChanged(
            currentMasterdataAirFreightSuitability,
            asset.masterdata?.air_freight_suitability
        );
        const hasMasterdataCommentChanged = stringHasChanged(currentMasterdataComment, asset.masterdata?.comment);
        const hasMasterdataCustomsBondChanged = stringHasChanged(
            currentMasterdataCustomsBond,
            asset.masterdata?.customs_bond
        );
        const hasMasterdataCostCenterChanged = stringHasChanged(
            currentMasterdataCostCenter,
            asset.masterdata?.cost_center
        );
        const hasMasterdataFuelTypeChanged = stringHasChanged(currentMasterdataFuelType, asset.masterdata?.fuel_type);
        const hasMasterdataLeasingRateChanged = numberHasChanged(
            currentMasterdataLeasingRate,
            asset.masterdata?.ownership?.contract_rate
        );
        const hasMasterdataNumberOfAxesChanged = numberHasChanged(
            currentMasterdataNumberOfAxes,
            asset.masterdata?.number_of_axes
        );
        const hasMasterdataOwnershipTypeChanged = stringHasChanged(
            currentMasterdataOwnershipType,
            asset.masterdata?.ownership?.type
        );
        const hasMasterdataOwnershipEndDateChanged = stringHasChanged(
            currentMasterdataOwnershipEndDate,
            asset.masterdata?.ownership?.ownership_end_at
        );
        const hasMasterdataOwnershipStartDateChanged = stringHasChanged(
            currentMasterdataOwnershipStartDate,
            asset.masterdata?.ownership?.ownership_start_at
        );
        const hasMasterdataReferenceChanged = stringHasChanged(
            currentMasterdataReference,
            asset.masterdata?.ownership?.reference
        );
        const hasMasterdataRegisteredFirstAtChanged = stringHasChanged(
            currentMasterdataRegisteredFirstAt,
            asset.masterdata?.ownership?.registered_first_at
        );
        const hasMasterdataOwnershipTermInMonthsChanged = numberHasChanged(
            currentMasterdataOwnershipTermInMonths,
            asset.masterdata?.ownership?.contract_duration_in_months
        );
        const hasMasterdataVehicleModelChanged = stringHasChanged(
            currentMasterdataVehicleModel,
            asset.masterdata?.vehicle_model
        );
        const hasMasterdataEngineTypeChanged = stringHasChanged(
            currentMasterdataEngineType,
            asset.masterdata?.engine_type
        );
        const hasMasterdataTiresChanged = stringHasChanged(currentMasterdataTires, asset.masterdata?.tires);
        const hasMasterdataChanged: boolean =
            hasMasterdataAdrChanged ||
            hasMasterdataAirFreightSuitabilityChanged ||
            hasMasterdataCommentChanged ||
            hasMasterdataCustomsBondChanged ||
            hasMasterdataCostCenterChanged ||
            hasMasterdataFuelTypeChanged ||
            hasMasterdataLeasingRateChanged ||
            hasMasterdataNumberOfAxesChanged ||
            hasMasterdataOwnershipTypeChanged ||
            hasMasterdataOwnershipEndDateChanged ||
            hasMasterdataOwnershipStartDateChanged ||
            hasMasterdataReferenceChanged ||
            hasMasterdataRegisteredFirstAtChanged ||
            hasMasterdataOwnershipTermInMonthsChanged ||
            hasMasterdataTiresChanged ||
            hasMasterdataEngineTypeChanged ||
            hasMasterdataVehicleModelChanged;

        return (
            hasNameChanged ||
            hasIdentificationChanged ||
            hasLicensePlateChanged ||
            hasLicensePlateCountryCodeChanged ||
            hasTagsChanged ||
            hasStatusChanged ||
            hasMasterdataChanged
        );
    }, [
        asset,
        currentAssetLicensePlate,
        currentAssetLicensePlateCountryCode,
        currentAssetName,
        currentAssetStatus,
        currentAssetIdentification,
        currentMasterdataAdr,
        currentMasterdataAirFreightSuitability,
        currentMasterdataComment,
        currentMasterdataCustomsBond,
        currentMasterdataCostCenter,
        currentMasterdataFuelType,
        currentMasterdataLeasingRate,
        currentMasterdataNumberOfAxes,
        currentMasterdataOwnershipEndDate,
        currentMasterdataOwnershipStartDate,
        currentMasterdataOwnershipType,
        currentMasterdataReference,
        currentMasterdataRegisteredFirstAt,
        currentMasterdataOwnershipTermInMonths,
        currentMasterdataTires,
        currentMasterdataVehicleModel,
        currentMasterdataEngineType,
        unsavedTags,
    ]);

    useEffect(() => {
        toggleUnsavedAssetChanges(hasUnsavedChanges());
    }, [toggleUnsavedAssetChanges, hasUnsavedChanges]);

    const resetState = useCallback(() => {
        setCurrentAssetLicensePlate(asset.license_plate);
        setCurrentAssetLicensePlateCountryCode(asset.license_plate_country_code);
        setCurrentAssetName(asset.name);
        setCurrentAssetIdentification(asset.identification);
        setCurrentAssetBrand(asset.brand);
        setCurrentAssetStatus(asset.status);
        setMasterdataAdr(asset.masterdata?.adr ?? undefined);
        setMasterdataAirFreightSuitability(asset.masterdata?.air_freight_suitability ?? undefined);
        setMasterdataComment(asset.masterdata?.comment ?? undefined);
        setMasterdataCustomsBond(asset.masterdata?.customs_bond ?? undefined);
        setMasterdataCostCenter(asset.masterdata?.cost_center ?? undefined);
        setMasterdataFuelType(asset.masterdata?.fuel_type ?? undefined);
        setMasterdataLeasingRate(asset.masterdata?.ownership?.contract_rate ?? undefined);
        setMasterdataNumberOfAxes(asset.masterdata?.number_of_axes ?? undefined);
        setMasterdataOwnershipEndDate(asset.masterdata?.ownership?.ownership_end_at ?? undefined);
        setMasterdataOwnershipStartDate(asset.masterdata?.ownership?.ownership_start_at ?? undefined);
        setMasterdataOwnershipType(asset.masterdata?.ownership?.type ?? undefined);
        setMasterdataReference(asset.masterdata?.ownership?.reference ?? undefined);
        setMasterdataRegisteredFirstAt(asset.masterdata?.ownership?.registered_first_at ?? undefined);
        setMasterdataOwnershipTermInMonths(asset.masterdata?.ownership?.contract_duration_in_months ?? undefined);
        setMasterdataTires(asset.masterdata?.tires ?? undefined);
        setMasterdataVehicleModel(asset.masterdata?.vehicle_model ?? undefined);
        setMasterdataEngineType(asset.masterdata?.engine_type ?? undefined);
        setUnsavedTags([]);
        setIdentificationHasError(false);
    }, [asset]);

    const fetchDevices = useCallback(
        () => fetchSelectedDevices(asset.id, associatedDevices),
        [fetchSelectedDevices, asset, associatedDevices]
    );

    useEffect(() => {
        void fetchDevices();
        resetState();
    }, [asset, resetState, fetchDevices, associatedDevices]);

    useEffect(() => {
        //
        if (deletionSuccessful) {
            closableSuccessNotification(<FormattedMessage id={'assets.assets.delete.success'} />);
            resetAssetDeletionState();
            onClose();
        }
    }, [deletionSuccessful, onClose, resetAssetDeletionState]);

    const onCloseCallback = () => {
        if (hasUnsavedChanges()) {
            toggleConfirmDialog(true, null, onClose);
        } else {
            onClose();
        }
    };

    const isCurrentAssetNameEmpty = currentAssetName?.trim().length === 0;
    const isSaveButtonDisabled =
        !hasUnsavedChanges() ||
        isCurrentAssetNameEmpty ||
        assetUpdating ||
        assetTagsUpdating ||
        !isAssetIdentificationValid(currentAssetIdentification);

    const submitButtonClick = () => {
        if (asset.status === AssetStatus.active && currentAssetStatus === AssetStatus.archived) {
            setShowArchiveAssetConfirmationDialog(true);
        } else {
            performAssetUpdate().finally(() => performMasterdataUpdate());
        }
    };

    const performAssetUpdate = () => createAndUpdateAssetAndTag(unsavedTags).finally(() => setUnsavedTags([]));

    const performMasterdataUpdate = () => {
        return updateMasterdata(asset, asset.masterdata, {
            adr: trimValue(currentMasterdataAdr),
            air_freight_suitability: trimValue(currentMasterdataAirFreightSuitability),
            comment: trimValue(currentMasterdataComment),
            customs_bond: trimValue(currentMasterdataCustomsBond),
            cost_center: trimValue(currentMasterdataCostCenter),
            fuel_type: currentMasterdataFuelType,
            number_of_axes: currentMasterdataNumberOfAxes,
            plug_and_charge_ids: undefined,
            tires: trimValue(currentMasterdataTires),
            vehicle_model: currentMasterdataVehicleModel,
            engine_type: currentMasterdataEngineType,
            ownership: {
                contract_duration_in_months: currentMasterdataOwnershipTermInMonths,
                contract_rate: currentMasterdataLeasingRate,
                ownership_end_at: currentMasterdataOwnershipEndDate,
                ownership_start_at: currentMasterdataOwnershipStartDate,
                reference: trimValue(currentMasterdataReference),
                registered_first_at: currentMasterdataRegisteredFirstAt,
                type: currentMasterdataOwnershipType,
            },
        });
    };

    const archiveAndUpdateAsset = () =>
        performAssetUpdate().finally(() => setShowArchiveAssetConfirmationDialog(false));

    const onTagListChange = useCallback((selectedTags: SelectedTag[]) => {
        setUnsavedTags(selectedTags.filter((tag) => tag.status !== SelectedTagStatus.unchanged));
    }, []);

    const createAndUpdateAssetAndTag = (tagsToUpdate: SelectedTag[]) => {
        const newTags = tagsToUpdate.filter((currentTag) => currentTag.status === SelectedTagStatus.created);
        const updatedTags = tagsToUpdate.filter(
            (currentTag) =>
                currentTag.status === SelectedTagStatus.added || currentTag.status === SelectedTagStatus.removed
        );
        if (asset) {
            return Promise.all([
                createAssetTags(newTags, asset),
                updateAsset(asset, updatedAsset),
                updateAssetTags(updatedAsset, updatedTags),
            ]);
        } else {
            return Promise.resolve();
        }
    };

    const handleTabClick = (event: React.MouseEvent, selectedAssetsListDetailTab: AssetsListDetailTab) => {
        event.preventDefault();
        event.stopPropagation();
        if (hasUnsavedChanges()) {
            // this fixes the behavior that the tag manager cannot display changes like tags added/removed
            // when it gets newly mounted
            toggleConfirmDialog(true, asset.id as string, () => {
                resetState();
                toggleConfirmDialog(false, asset.id as string, onClose);
                changeTabAndUpdateUrl(selectedAssetsListDetailTab);
            });
        } else {
            changeTabAndUpdateUrl(selectedAssetsListDetailTab);
        }
    };
    const changeTabAndUpdateUrl = (assetsListDetailTab: AssetsListDetailTab) => {
        if (asset && assetsListDetailTab !== currentAssetDetailsTab) {
            changeAssetDetailsTab(assetsListDetailTab as AssetsListDetailTab);
        }
    };

    const handleAssetDetailTabClick = (e: React.MouseEvent) => {
        handleTabClick(e, AssetsListDetailTab.ASSET_DETAILS);
    };
    const handleDeviceListTabClick = (e: React.MouseEvent) => {
        handleTabClick(e, AssetsListDetailTab.DEVICE_LIST);
    };
    const handleTagListTabClick = (e: React.MouseEvent) => {
        handleTabClick(e, AssetsListDetailTab.TAG_LIST);
    };

    const handleAssetStatusChange = () => {
        if (currentAssetStatus === AssetStatus.active) {
            setCurrentAssetStatus(AssetStatus.archived);
        } else {
            setCurrentAssetStatus(AssetStatus.active);
        }
    };

    const handleDeleteAsset = () => {
        return deleteAsset(updatedAsset);
    };

    const handleMasterdataOwnershipTermInMonthsChange = (termInMonths: number | null | undefined) => {
        setMasterdataOwnershipTermInMonths(termInMonths);
        if (!isNullOrEmpty(currentMasterdataOwnershipStartDate)) {
            const startDate = moment(currentMasterdataOwnershipStartDate, DATE_FORMAT);
            const endDate = startDate.add(termInMonths, 'M');
            setMasterdataOwnershipEndDate(endDate.format(DATE_FORMAT));
            setMasterdataOwnershipEndDateChangeJustTriggered(true);
        }
    };

    const handleMasterdataOwnershipStartDate = (ownershipStartDate: string | undefined) => {
        setMasterdataOwnershipStartDate(ownershipStartDate);
        if (
            !isNullOrEmpty(currentMasterdataOwnershipEndDate) &&
            !isNullOrEmpty(ownershipStartDate) &&
            endDateIsSameOrAfterStartDateOrNull(ownershipStartDate, currentMasterdataOwnershipEndDate)
        ) {
            const startDate = moment(ownershipStartDate, DATE_FORMAT);
            const endDate = moment(currentMasterdataOwnershipEndDate, DATE_FORMAT);
            setMasterdataOwnershipTermInMonths(endDate.diff(startDate, 'months', false));
            setMasterdataOwnershipTermInMonthsChangeJustTriggered(true);
        }
    };
    const handleMasterdataOwnershipEndDate = (ownershipEndDate: string | undefined) => {
        setMasterdataOwnershipEndDate(ownershipEndDate);
        if (
            !isNullOrEmpty(currentMasterdataOwnershipStartDate) &&
            !isNullOrEmpty(ownershipEndDate) &&
            endDateIsSameOrAfterStartDateOrNull(currentMasterdataOwnershipStartDate, ownershipEndDate)
        ) {
            const startDate = moment(currentMasterdataOwnershipStartDate, DATE_FORMAT);
            const endDate = moment(ownershipEndDate, DATE_FORMAT);
            setMasterdataOwnershipTermInMonths(endDate.diff(startDate, 'months', false));
            setMasterdataOwnershipTermInMonthsChangeJustTriggered(true);
        }
    };

    const triggerTimeoutMS = 1500;
    useEffect(() => {
        setTimeout(() => setMasterdataOwnershipEndDateChangeJustTriggered(false), triggerTimeoutMS);
    }, [currentMasterdataOwnershipEndDateChangeJustTriggered]);

    useEffect(() => {
        setTimeout(() => setMasterdataOwnershipTermInMonthsChangeJustTriggered(false), triggerTimeoutMS);
    }, [currentMasterdataOwnershipTermInMonthsChangeJustTriggered]);

    const getTabContent = (assetsListDetailTab: AssetsListDetailTab) => {
        switch (assetsListDetailTab) {
            case AssetsListDetailTab.ASSET_DETAILS:
                return (
                    <AssetDetails
                        asset={asset}
                        currentAssetLicensePlate={currentAssetLicensePlate}
                        currentAssetLicensePlateCountryCode={currentAssetLicensePlateCountryCode}
                        currentAssetName={currentAssetName}
                        currentAssetStatus={currentAssetStatus}
                        currentAssetIdentification={currentAssetIdentification}
                        currentAssetBrand={currentAssetBrand}
                        currentMasterdataAdr={currentMasterdataAdr}
                        currentMasterdataAirFreightSuitability={currentMasterdataAirFreightSuitability}
                        currentMasterdataComment={currentMasterdataComment}
                        currentMasterdataCustomsBond={currentMasterdataCustomsBond}
                        currentMasterdataCostCenter={currentMasterdataCostCenter}
                        currentMasterdataFuelType={currentMasterdataFuelType}
                        currentMasterdataLeasingRate={currentMasterdataLeasingRate}
                        currentMasterdataNumberOfAxes={currentMasterdataNumberOfAxes}
                        currentMasterdataVehicleModel={currentMasterdataVehicleModel}
                        currentMasterdataEngineType={currentMasterdataEngineType}
                        currentMasterdataOwnershipEndDate={currentMasterdataOwnershipEndDate}
                        currentMasterdataOwnershipEndDateChangeJustTriggered={
                            currentMasterdataOwnershipEndDateChangeJustTriggered
                        }
                        currentMasterdataOwnershipStartDate={currentMasterdataOwnershipStartDate}
                        currentMasterdataOwnershipTermInMonths={currentMasterdataOwnershipTermInMonths}
                        currentMasterdataOwnershipTermInMonthsChangeJustTriggered={
                            currentMasterdataOwnershipTermInMonthsChangeJustTriggered
                        }
                        currentMasterdataOwnershipType={currentMasterdataOwnershipType}
                        currentMasterdataReference={currentMasterdataReference}
                        currentMasterdataRegisteredFirstAt={currentMasterdataRegisteredFirstAt}
                        currentMasterdataTires={currentMasterdataTires}
                        identificationHasError={identificationHasError}
                        locale={props.intl.locale}
                        onAssetIdentificationChange={setCurrentAssetIdentification}
                        onAssetBrandChange={setCurrentAssetBrand}
                        onAssetLicensePlateChange={setCurrentAssetLicensePlate}
                        onAssetLicensePlateCountryCodeChange={setCurrentAssetLicensePlateCountryCode}
                        onAssetNameChange={setCurrentAssetName}
                        onAssetStatusChange={handleAssetStatusChange}
                        onMasterdataAdr={setMasterdataAdr}
                        onMasterdataAirFreightSuitability={setMasterdataAirFreightSuitability}
                        onMasterdataComment={setMasterdataComment}
                        onMasterdataCustomsBond={setMasterdataCustomsBond}
                        onMasterdataCostCenter={setMasterdataCostCenter}
                        onMasterdataFuelType={setMasterdataFuelType}
                        onMasterdataLeasingRate={setMasterdataLeasingRate}
                        onMasterdataNumberOfAxes={setMasterdataNumberOfAxes}
                        onMasterdataVehicleModel={setMasterdataVehicleModel}
                        onMasterdataEngineType={setMasterdataEngineType}
                        onMasterdataOwnershipType={setMasterdataOwnershipType}
                        onMasterdataOwnershipEndDate={handleMasterdataOwnershipEndDate}
                        onMasterdataOwnershipStartDate={handleMasterdataOwnershipStartDate}
                        onMasterdataReference={setMasterdataReference}
                        onMasterdataRegisteredFirstAt={setMasterdataRegisteredFirstAt}
                        onMasterdataOwnershipTermInMonths={handleMasterdataOwnershipTermInMonthsChange}
                        onMasterdataTires={setMasterdataTires}
                        setIdentificationHasError={setIdentificationHasError}
                        associatedDevices={associatedDevices}
                    />
                );
            case AssetsListDetailTab.TAG_LIST:
                return (
                    <TagManagerWrapper
                        accountId={asset.account_id}
                        assetTags={asset.tags}
                        onTagListChange={onTagListChange}
                        availableTags={availableTags}
                        useCustomTags={true}
                        customMessage={'assets.assets.asset-list-detail.tag-manager.message'}
                    />
                );
            case AssetsListDetailTab.DEVICE_LIST:
                return <DeviceList />;
        }
    };

    const tabLiClasses = (assetsListDetailTab: AssetsListDetailTab) => {
        return currentAssetDetailsTab === assetsListDetailTab ? 'active' : '';
    };

    const sideBarContent = (
        <React.Fragment>
            <ul className="nav nav-pills nav-justified margin-bottom-10">
                <li className={tabLiClasses(AssetsListDetailTab.ASSET_DETAILS)}>
                    <a data-cy="asset-details-asset-tab" onClick={handleAssetDetailTabClick}>
                        {AssetDetails.headingText}
                    </a>
                </li>
                <li className={tabLiClasses(AssetsListDetailTab.TAG_LIST)}>
                    <a data-cy="asset-details-tags-tab" onClick={handleTagListTabClick}>
                        <FormattedMessage id={'assets.assets.asset.tags'} />
                    </a>
                </li>
                <li className={tabLiClasses(AssetsListDetailTab.DEVICE_LIST)}>
                    <a data-cy="asset-details-devices-tab" onClick={handleDeviceListTabClick}>
                        <FormattedMessage id={'assets.assets.asset.devices'} />
                    </a>
                </li>
            </ul>
            {getTabContent(currentAssetDetailsTab)}
        </React.Fragment>
    );
    const updatedAsset: Asset = {
        ...asset,
        name: currentAssetName ?? '',
        identification_type: currentAssetIdentification ? IdentificationType.vin : null, // we currently only support vin as identification
        identification: currentAssetIdentification,
        brand: currentAssetBrand,
        license_plate:
            currentAssetLicensePlate && currentAssetLicensePlate.length > 0 ? currentAssetLicensePlate.trim() : null,
        license_plate_country_code: currentAssetLicensePlateCountryCode,
        status: currentAssetStatus,
        tags: unsavedTags.filter((tag) => tag.status !== SelectedTagStatus.removed).map((tag) => tag.id),
    };
    return (
        <>
            <Sidebar
                title={currentAssetName}
                closed={false}
                position={'right'}
                onClose={onCloseCallback}
                footer={
                    <SidebarFooter
                        saveButtonDisabled={isSaveButtonDisabled}
                        modalDeleteButtonDisabled={assetDeleting}
                        loadingOnSave={assetUpdating || assetTagsUpdating}
                        displayDeleteDialog={displayDeleteDialog}
                        deleteAsset={handleDeleteAsset}
                        openDeleteDialog={openDeleteDialog}
                        closeDeleteDialog={closeDeleteDialog}
                        deletionErrorMessageCode={deletionErrorMessageCode}
                        updatedAsset={updatedAsset}
                        submitButtonClick={submitButtonClick}
                        toggleUnsavedAssetChanges={toggleUnsavedAssetChanges}
                    />
                }
                resizable={true}
                minWidth={300}
                width={400}
                switchModeBreakpoint={SIDEBAR_BREAKPOINT}
                titleClassName={'padding-left-10'}
                bodyClassName={'padding-left-20 padding-right-20'}
            >
                {sideBarContent}
            </Sidebar>
            {showArchiveAssetConfirmationDialog && (
                <ArchiveAssetConfirmationDialog
                    asset={asset}
                    show={showArchiveAssetConfirmationDialog}
                    updateAsset={archiveAndUpdateAsset}
                    abortArchivingAsset={() => setShowArchiveAssetConfirmationDialog(false)}
                />
            )}
        </>
    );
};
