import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormInputFieldProperties, FormInputFieldType } from './types';
import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';
import { closableSuccessNotification } from './ClickableNotifications';

const copiedFeedbackMessage = (currentValue: string) => {
    closableSuccessNotification(
        <FormattedMessage id={'assets.assets.copy-feedback'}>
            {(snippet) => {
                return <>{'"' + currentValue + '" ' + snippet}</>;
            }}
        </FormattedMessage>
    );
};

const FormInputField = (props: React.PropsWithChildren<FormInputFieldProperties>) => {
    const formGroupClassNames = classNames(
        'form-group',
        props.hasError && 'has-error',
        props.hasFeedback && 'has-feedback'
    );
    const lengthValidationClassNames = classNames(
        props.hasError && 'text-color-danger',
        !props.hasError && 'text-color-highlight',
        'input-group-addon'
    );

    return (
        <div className={formGroupClassNames}>
            <label>
                <FormattedMessage id={props.translationId} values={props.messageValues} />
            </label>
            <div className="input-group">
                {(props.type === undefined || props.type === FormInputFieldType.TEXT) && (
                    <input
                        className="form-control"
                        type="text"
                        readOnly={props.disabled}
                        value={props.currentValue ? props.currentValue : ''}
                        onChange={props.onValueChange ? (it) => props.onValueChange!(it.currentTarget.value) : () => {}}
                        maxLength={props.maxLength ? props.maxLength : -1}
                        data-cy={props.dataAttribute && props.dataAttribute}
                    />
                )}
                {props.type === FormInputFieldType.TEXTAREA && (
                    <textarea
                        className="form-control"
                        readOnly={props.disabled}
                        value={props.currentValue ? props.currentValue : ''}
                        onChange={props.onValueChange ? (it) => props.onValueChange!(it.currentTarget.value) : () => {}}
                        maxLength={props.maxLength ? props.maxLength : -1}
                        data-cy={props.dataAttribute && props.dataAttribute}
                    />
                )}
                {props.hasLengthValidation && props.maxLength ? (
                    <div className={lengthValidationClassNames}>
                        {props.currentValue === '' ? '' : `${props.currentValue.length}/${props.maxLength}`}
                    </div>
                ) : (
                    <></>
                )}
                {props.withCopyButton ? (
                    <span className="input-group-btn" onClick={() => copiedFeedbackMessage(props.currentValue)}>
                        <CopyToClipboard text={props.currentValue}>
                            <button
                                className={'btn btn-default ' + (props.currentValue ? '' : 'disabled')}
                                type="button"
                                data-cy={props.dataAttribute && `${props.dataAttribute}-copy-btn`}
                            >
                                <span className="rioglyph rioglyph-duplicate" />
                                <span>Copy</span>
                            </button>
                        </CopyToClipboard>
                    </span>
                ) : (
                    <React.Fragment />
                )}
                {props.children}
            </div>
            {props.hasFeedback && props.feedbackMsg && <span className={'help-block'}>{props.feedbackMsg}</span>}
        </div>
    );
};

export default FormInputField;
