import React from 'react';
import Tag from '@rio-cloud/rio-uikit/Tag';
import { AssetDevicesProps } from './types';
import TelematicsName from './TelematicsName';

export const AssetDevices = (props: AssetDevicesProps) => (
    <ul className="list-inline margin-bottom-0">
        {props.assetDevices.map((associatedDevice) => (
            <li key={associatedDevice.device.id} className="margin-1">
                <Tag size={'small'}>
                    <TelematicsName deviceType={associatedDevice.device.type} />{' '}
                </Tag>
            </li>
        ))}
    </ul>
);
