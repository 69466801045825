import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import { BulkArchiveAssetConfirmationDialog } from './BulkArchiveAssetConfirmationDialog';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import AssetsListDetailContainer from '../../containers/assets/details/AssetsListDetail.container';
import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import { ReleaseInfoBannerContent } from '../ReleaseInfoBannerContent';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import React, { useEffect, useState } from 'react';
import { AssetsListToolbar } from './AssetsListToolbar';
import FilterOptionsDialogContainer from '../../containers/assets/FilterOptionsDialog.container';
import { AssetsList } from './AssetsList';
import LoadMoreButton from '@rio-cloud/rio-uikit/LoadMoreButton';
import { DeviceType, SelectedTag, SelectedTagStatus } from './details/types';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import {
    assetTableSortChanges,
    getAssets,
    getAssetsTableViewType,
    getAssetTableSortColumn,
    getAssetTableSortDir,
    getAssetTagLastSuccessfullyAdded,
    getAssetTagLastSuccessfullyRemoved,
    getAssetTagsLastFailedAdded,
    getAssetTagsLastFailedRemoved,
    getAssetUpdateErrorCode,
    getAssetUpdateFailed,
    getAssetUpdateSuccessful,
    getAssociatedDevices,
    getFetching,
    getSearchString,
    getShowActiveAssets,
    getShowArchivedAssets,
    getShowOnlyAssetsWithoutDataSource,
    resetAssetTagsUpdatingState,
    resetAssetUpdateState,
    setSelectedAssetId,
} from '../../reducers/assets/assetsSlice';
import { getTags } from '../../reducers/tags/tagsSlice';
import { getUserAccount } from '../../../../configuration/login/loginSlice';
import { createAssetTags, fetchTags } from '../../actions/tags/Tags.actions';
import { fetchAssets, fetchDevices, updateAssetTags } from '../../actions/assets/Assets.actions';
import { Asset, AssetStatus, AssetType, AssociatedDevice, IdentificationType, Tag } from './types';
import { TagManagerWrapper } from './details/TagManagerWrapper';
import intersection from 'lodash/intersection';
import { deviceTypeToDisplayedString } from './TelematicsName';
import { Identifier, orderBy } from 'natural-orderby';
import { closableSuccessNotification, nonVanishingErrorNotification } from './ClickableNotifications';
import FormattedMessageWithFallback from '../../utils/FormattedMessageWithFallback';
import { ASSETS_PER_PAGE, COLUMNS_EXCLUDED_FROM_SORTING } from './assetsUtils';
import SortDirection, { type SortDirectionType } from '@rio-cloud/rio-uikit/SortDirection';

const IS_FEATURE_BANNER_ENABLED = false;

export const Assets = () => {
    // Redux hooks
    const dispatch = useAppDispatch();

    const selectedAssetId = useAppSelector((state) => state.assets.selectedAssetId);
    const tableViewType = useAppSelector(getAssetsTableViewType);
    const assets = useAppSelector(getAssets);
    const devices = useAppSelector(getAssociatedDevices);
    const fetching = useAppSelector(getFetching);
    const availableTags = useAppSelector(getTags);
    const accountId = useAppSelector(getUserAccount) ?? null;
    const searchString = useAppSelector(getSearchString);
    const showOnlyAssetsWithoutDataSource = useAppSelector(getShowOnlyAssetsWithoutDataSource);
    const showActiveAssets = useAppSelector(getShowActiveAssets);
    const showArchivedAssets = useAppSelector(getShowArchivedAssets);
    const sortBy = useAppSelector(getAssetTableSortColumn);
    const sortDir = useAppSelector(getAssetTableSortDir);
    const assetTagLastSuccessfullyRemoved = useAppSelector(getAssetTagLastSuccessfullyRemoved);
    const assetTagLastFailedRemoved = useAppSelector(getAssetTagsLastFailedRemoved);
    const assetTagLastSuccessfullyAdded = useAppSelector(getAssetTagLastSuccessfullyAdded);
    const assetTagLastFailedAdded = useAppSelector(getAssetTagsLastFailedAdded);
    const updateSuccessful = useAppSelector(getAssetUpdateSuccessful);
    const updateFailed = useAppSelector(getAssetUpdateFailed);
    const updateErrorCode = useAppSelector(getAssetUpdateErrorCode);

    const intl = useIntl();

    // State hooks
    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
    const [unsavedAssetChanges, setUnsavedAssetChanges] = useState(false);
    const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
    const [tagManagerSelectedTags, setTagManagerSelectedTags] = useState<SelectedTag[]>([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showAddToGroupDialog, setShowAddToGroupDialog] = useState(false);
    const [showBulkArchiveDialog, setShowBulkArchiveDialog] = useState(false);
    const [showRemoveFromGroupDialog, setShowRemoveFromGroupDialog] = useState(false);
    const [numberOfAssetsToDisplay, setNumberOfAssetsToDisplay] = useState(ASSETS_PER_PAGE);
    const [nextRowId, setNextRowId] = useState<string | null>(null);

    // Other hooks (useMemo, useCallback, useEffect)
    useEffect(() => {
        dispatch(fetchAssets());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchDevices());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchTags(accountId));
    }, [dispatch, accountId]);

    useEffect(() => {
        const nextSelectedAsset = assets.find((asset) => asset.id === selectedAssetId) || null;
        setSelectedAsset(nextSelectedAsset);
    }, [setSelectedAsset, selectedAssetId, assets]);

    useEffect(() => {
        if (assetTagLastSuccessfullyRemoved) {
            const tagName = assetTagLastSuccessfullyRemoved.name;
            closableSuccessNotification(
                <FormattedMessage id={'assets.assets.asset.tag.remove.success'} values={{ tagName }} />,
                'success-notification-tag-removed'
            );
        }
    }, [assetTagLastSuccessfullyRemoved]);

    useEffect(() => {
        if (assetTagLastFailedRemoved) {
            const tagName = assetTagLastFailedRemoved.name;
            nonVanishingErrorNotification(
                <FormattedMessage id={'assets.assets.asset.tag.remove.failure'} values={{ tagName }} />,
                'T01'
            );
        }
    }, [assetTagLastFailedRemoved]);

    useEffect(() => {
        if (assetTagLastSuccessfullyAdded) {
            const tagName = assetTagLastSuccessfullyAdded.name;
            closableSuccessNotification(
                <FormattedMessage id={'assets.assets.asset.tag.add.success'} values={{ tagName }} />,
                'success-notification-tag-added'
            );
        }
    }, [assetTagLastSuccessfullyAdded]);

    useEffect(() => {
        if (assetTagLastFailedAdded) {
            const tagName = assetTagLastFailedAdded.name;
            nonVanishingErrorNotification(
                <FormattedMessage id={'assets.assets.asset.tag.add.failure'} values={{ tagName }} />,
                'T02'
            );
            dispatch(resetAssetTagsUpdatingState());
        }
    }, [dispatch, assetTagLastFailedAdded]);

    useEffect(() => {
        if (updateSuccessful) {
            closableSuccessNotification(<FormattedMessage id={'assets.assets.update.success'} />);
            dispatch(resetAssetUpdateState());
        } else if (updateFailed) {
            nonVanishingErrorNotification(
                <FormattedMessageWithFallback
                    id={`assets.assets.create.error${updateErrorCode ? `.${updateErrorCode}` : ''}`}
                    fallbackId={'assets.assets.update.failure'}
                />,
                updateErrorCode ?? 'AXX'
            );
            dispatch(resetAssetUpdateState());
        }
    }, [dispatch, updateSuccessful, updateFailed, updateErrorCode]);

    const isTargetCheckbox = (event: React.MouseEvent) => {
        const target = event.target as Element;
        const classlistValue = target.classList.value;
        return classlistValue === 'checkbox-text' || classlistValue === 'checkbox';
    };

    const handleRowClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const rowId = event.currentTarget.getAttribute('data-key');
        if (unsavedAssetChanges && rowId !== null) {
            if (rowId !== selectedAssetId) {
                setNextRowId(rowId);
            }
            setShowConfirmDialog(true);
        } else {
            if (isTargetCheckbox(event) && rowId != null) {
                toggleAssetIdSelection(rowId);
            } else if (rowId && rowId !== selectedAssetId) {
                dispatch(setSelectedAssetId(rowId));
            } else {
                dispatch(setSelectedAssetId(null));
            }
        }
    };

    const dispatchHandleSortChange = (column: string, sortDir: SortDirectionType) => {
        if (!COLUMNS_EXCLUDED_FROM_SORTING.includes(column)) {
            dispatch(assetTableSortChanges({ sortBy: column, sortDir: sortDir }));
        }
    };

    const handleSortChange = (column: string) => {
        if (column === sortBy) {
            const newSortDir = sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
            dispatchHandleSortChange(column, newSortDir);
        } else {
            dispatchHandleSortChange(column, SortDirection.ASCENDING);
        }
    };

    const handleToggleAll = (shouldSelect: boolean) => {
        const allDisplayedRowIds = getFilteredAssets().map((asset) => asset.id);

        if (shouldSelect) {
            setSelectedAssetIds(allDisplayedRowIds);
        } else {
            setSelectedAssetIds([]);
        }
    };

    const handleLoadMore = () => {
        setNumberOfAssetsToDisplay(numberOfAssetsToDisplay + ASSETS_PER_PAGE);
    };

    const toggleAssetIdSelection = (assetId: string) => {
        const newSelectedAssetIds = selectedAssetIds.includes(assetId)
            ? selectedAssetIds.filter((selectedAssetId) => selectedAssetId !== assetId)
            : selectedAssetIds.concat(assetId);

        setSelectedAssetIds(newSelectedAssetIds);
    };

    const createNewTags = (tagsToUpdate: SelectedTag[]) => {
        const newTags = tagsToUpdate.filter((currentTag) => currentTag.status === SelectedTagStatus.created);
        dispatch(createAssetTags(newTags));
    };

    const updateAssetTag = (asset: Asset, tagsToUpdate: SelectedTag[]) => {
        dispatch(updateAssetTags(asset, tagsToUpdate));
    };

    const assetNeedsTagUpdate = (selectedTags: SelectedTag[], asset: Asset) => {
        return selectedTags.some((assetTag) => {
            if (assetTag.status === SelectedTagStatus.added) {
                return !asset.tags.includes(assetTag.id);
            } else if (assetTag.status === SelectedTagStatus.removed) {
                return asset.tags.includes(assetTag.id);
            } else {
                return true;
            }
        });
    };

    const createAndUpdateAssetTagsFromSelection = () => {
        createNewTags(tagManagerSelectedTags);
        const selectedTags = tagManagerSelectedTags;
        const selectedAssets = assets.filter(
            (asset) => selectedAssetIds.includes(asset.id) && assetNeedsTagUpdate(selectedTags, asset)
        );
        selectedAssets.forEach((asset) => {
            updateAssetTag(asset, tagManagerSelectedTags);
        });
    };

    const onClickAddToGroupDialogConfirm = () => {
        setShowAddToGroupDialog(false);
        createAndUpdateAssetTagsFromSelection();
    };

    const onClickRemoveFromGroupDialogConfirm = () => {
        setShowRemoveFromGroupDialog(false);
        createAndUpdateAssetTagsFromSelection();
    };

    const onSidebarChange = () => {
        setUnsavedAssetChanges(false);
        setShowConfirmDialog(false);
        setNextRowId(null);
        dispatch(setSelectedAssetId(nextRowId));
    };

    const getAddToGroupDialogAvailableTags = () => {
        const selectedTagsIntersection = intersection(
            ...assets.filter((asset) => selectedAssetIds.includes(asset.id)).map((asset) => asset.tags)
        );
        return availableTags.filter((tag) => !selectedTagsIntersection.includes(tag.id));
    };

    const getRemoveFromGroupDialogAssetTags = () =>
        assets.filter((asset) => selectedAssetIds.includes(asset.id)).flatMap((asset) => asset.tags);

    const getRemoveFromGroupDialogAvailableTags = () =>
        availableTags.filter((tag) => getRemoveFromGroupDialogAssetTags().includes(tag.id));

    const onTagListChange = (selectedTags: SelectedTag[]) => {
        setTagManagerSelectedTags(selectedTags.filter((tag) => tag.status !== SelectedTagStatus.unchanged));
    };

    const getDevicesForAsset = (assetId: string): AssociatedDevice[] => {
        const devicesForAsset = devices[assetId];
        return devicesForAsset === undefined ? [] : devicesForAsset;
    };

    const getFormattedMessageAssetIdentificationType = (identificationType: IdentificationType) =>
        intl
            .formatMessage({ id: `assets.assets.asset.identification_type.${identificationType.toString()}` })
            .toLowerCase();

    const getFormattedMessageAssetType = (assetType: AssetType) =>
        intl.formatMessage({ id: `assets.assets.asset.type.${assetType.toString()}` }).toLowerCase();

    const getFormattedMessageDeviceType = (deviceType: DeviceType) => deviceTypeToDisplayedString(deviceType);

    const filterBySearchValue = (assets: Asset[]): Asset[] => {
        const searchValue = searchString.toLocaleLowerCase();
        if (searchValue) {
            return searchValue.split(' ').reduce(
                (prevResult, searchString) =>
                    prevResult.filter(
                        (asset) =>
                            asset.name.toLowerCase().includes(searchString) ||
                            (asset.identification && asset.identification.toLowerCase().includes(searchString)) ||
                            (asset.identification_type &&
                                getFormattedMessageAssetIdentificationType(asset.identification_type).includes(
                                    searchString
                                )) ||
                            (asset.brand && asset.brand.toLowerCase().includes(searchString)) ||
                            (asset.type && getFormattedMessageAssetType(asset.type).includes(searchString)) ||
                            (asset.license_plate && asset.license_plate.toLowerCase().includes(searchString)) ||
                            availableTags
                                .filter((tag) => asset.tags.includes(tag.id))
                                .some((tag) => tag.name.toLowerCase().includes(searchString)) ||
                            getDevicesForAsset(asset.id).some(
                                (associatedDevice) =>
                                    associatedDevice.device.identification.toLowerCase() === searchString.toLowerCase()
                            ) ||
                            getDevicesForAsset(asset.id)
                                .map((associatedDevice) => getFormattedMessageDeviceType(associatedDevice.device.type))
                                .some((deviceType) => deviceType.includes(searchString))
                    ),
                assets
            );
        } else {
            return assets;
        }
    };

    const filteredByFilterOptions = (assets: Asset[]): Asset[] => {
        return assets
            .filter((asset) => (showOnlyAssetsWithoutDataSource ? getDevicesForAsset(asset.id).length === 0 : true))
            .filter((asset) => (!showActiveAssets ? asset.status !== AssetStatus.active : true))
            .filter((asset) => (!showArchivedAssets ? asset.status !== AssetStatus.archived : true));
    };

    const getFilteredAssets = () => {
        const assetsFilteredBySearchString = filterBySearchValue(assets);
        return filteredByFilterOptions(assetsFilteredBySearchString);
    };

    const getSortedAssets = (assets: Asset[]) => {
        if (sortBy) {
            return orderBy<Asset>(assets, [sortBy as Identifier<Asset>], [sortDir]);
        } else {
            return assets;
        }
    };

    const sortedAssets = getSortedAssets(getFilteredAssets());
    const assetsToRender = sortedAssets.slice(0, numberOfAssetsToDisplay);

    // Render helpers (optional)
    const renderTagManagerWrapper = (availableTags: Tag[], assetTags: string[] | undefined, useCustomTags: boolean) => (
        <TagManagerWrapper
            accountId={accountId}
            assetTags={assetTags}
            onTagListChange={onTagListChange}
            availableTags={availableTags}
            useCustomTags={useCustomTags}
            customMessage={
                useCustomTags
                    ? 'assets.assets.tag-manager.create-add-message'
                    : 'assets.assets.tag-manager.remove-message'
            }
        />
    );

    return (
        <>
            {showConfirmDialog && (
                <ConfirmationDialog
                    show={showConfirmDialog}
                    title={<FormattedMessage id={'assets.assets.confirmation-dialog.title'} />}
                    content={<FormattedMessage id={'assets.assets.confirmation-dialog.content'} />}
                    onClickConfirm={onSidebarChange}
                    onClickCancel={() => {
                        setNextRowId(null);
                        setShowConfirmDialog(false);
                    }}
                    cancelButtonText={<FormattedMessage id={'assets.assets.confirmation-dialog.button.cancel'} />}
                    confirmButtonText={<FormattedMessage id={'assets.assets.confirmation-dialog.button.confirm'} />}
                    disableConfirm={false}
                    useOverflow={false}
                    bsSize={'sm'}
                />
            )}
            {showAddToGroupDialog && (
                <ConfirmationDialog
                    show={showAddToGroupDialog}
                    title={<FormattedMessage id={'assets.assets.addToGroup'} />}
                    content={renderTagManagerWrapper(getAddToGroupDialogAvailableTags(), [], true)}
                    bsSize={'sm'}
                    onClickConfirm={onClickAddToGroupDialogConfirm}
                    onClickCancel={() => setShowAddToGroupDialog(false)}
                    cancelButtonText={<FormattedMessage id={'assets.assets.add-to-group-dialog.button.cancel'} />}
                    confirmButtonText={
                        <div data-cy={'confirmation-dialog-confirm-button'}>
                            <FormattedMessage id={'assets.assets.add-to-group-dialog.button.confirm'} />
                        </div>
                    }
                    useOverflow={false}
                />
            )}
            {showRemoveFromGroupDialog && (
                <ConfirmationDialog
                    show={showRemoveFromGroupDialog}
                    title={<FormattedMessage id={'assets.assets.removeFromGroup'} />}
                    content={renderTagManagerWrapper(
                        getRemoveFromGroupDialogAvailableTags(),
                        getRemoveFromGroupDialogAssetTags(),
                        false
                    )}
                    bsSize={'sm'}
                    onClickConfirm={onClickRemoveFromGroupDialogConfirm}
                    onClickCancel={() => setShowRemoveFromGroupDialog(false)}
                    cancelButtonText={<FormattedMessage id={'assets.assets.add-to-group-dialog.button.cancel'} />}
                    confirmButtonText={
                        <div data-cy={'confirmation-dialog-confirm-button'}>
                            <FormattedMessage id={'assets.assets.add-to-group-dialog.button.confirm'} />
                        </div>
                    }
                    useOverflow={false}
                />
            )}
            {showBulkArchiveDialog && (
                <BulkArchiveAssetConfirmationDialog
                    show={showBulkArchiveDialog}
                    assets={assets.filter((asset) => selectedAssetIds.includes(asset.id))}
                    hideBulkArchiveAssetDialog={() => setShowBulkArchiveDialog(false)}
                />
            )}
            <ApplicationLayout.Sidebar className="right">
                {selectedAsset && (
                    <AssetsListDetailContainer
                        asset={selectedAsset}
                        onClose={onSidebarChange}
                        toggleConfirmDialog={setShowConfirmDialog}
                        toggleUnsavedAssetChanges={setUnsavedAssetChanges}
                    />
                )}
            </ApplicationLayout.Sidebar>
            <ApplicationLayout.Body
                banner={
                    IS_FEATURE_BANNER_ENABLED && (
                        <ApplicationLayoutBodyBanner>
                            <ReleaseInfoBannerContent />
                        </ApplicationLayoutBodyBanner>
                    )
                }
            >
                {fetching ? (
                    <Spinner text={<FormattedMessage id={'assets.spinner.loading'} />} />
                ) : (
                    <>
                        <AssetsListToolbar
                            assets={sortedAssets}
                            filterOptionsDialog={<FilterOptionsDialogContainer />}
                        />
                        <AssetsList
                            assets={assetsToRender}
                            devices={devices}
                            selectedAsset={selectedAsset}
                            tableViewType={tableViewType}
                            handleRowClick={handleRowClick}
                            tags={availableTags}
                            handleSortChange={handleSortChange}
                            sortBy={sortBy}
                            sortDir={sortDir}
                            handleToggleAll={handleToggleAll}
                            selectedAssetIds={selectedAssetIds}
                            showAddToGroupDialog={setShowAddToGroupDialog}
                            showBulkArchiveDialog={setShowBulkArchiveDialog}
                            showRemoveFromGroupDialog={setShowRemoveFromGroupDialog}
                        />
                        {assetsToRender.length > 0 && (
                            <LoadMoreButton
                                data-test-id={'loadMoreButton'}
                                loaded={assetsToRender.length}
                                total={sortedAssets.length}
                                onLoadMore={handleLoadMore}
                                loadMoreMessage={<FormattedMessage id={'assets.assets.loadMore'} />}
                                noMoreMessage={<FormattedMessage id={'assets.assets.noMore'} />}
                            />
                        )}
                    </>
                )}
            </ApplicationLayout.Body>
        </>
    );
};
