import React from 'react';
import { CSVLink } from 'react-csv';
import { getDataForCsvLink } from './CsvExport.helper';
import { IntlShape, useIntl } from 'react-intl';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getTags } from '../../reducers/tags/tagsSlice';
import { Asset } from './types';
import { createSelector } from '@reduxjs/toolkit';
import { getAssociatedDevices } from '../../reducers/assets/assetsSlice';

const getDevices = createSelector([getAssociatedDevices], (devices) => {
    if (!devices) {
        return {};
    }
    return Object.fromEntries(
        Object.entries(devices).map(([assetId, associatedDevicesList]) => {
            return [assetId, associatedDevicesList.map((associatedDevice) => associatedDevice.device)];
        })
    );
});

const getFormattedMessage = (label: string, intl: IntlShape): string =>
    intl.formatMessage({ id: `assets.assets.asset.${label}` });

export const CsvExport = (props: { assets: Asset[] }) => {
    const availableTags = useAppSelector(getTags);
    const devices = useAppSelector(getDevices);

    const intl = useIntl();

    const headers = [
        { label: getFormattedMessage('name', intl), key: 'name' },
        { label: 'AssetId', key: 'assetId' },
        { label: getFormattedMessage('identification', intl), key: 'identification' },
        { label: getFormattedMessage('license-plate', intl), key: 'license_plate' },
        { label: getFormattedMessage('brand', intl), key: 'brand' },
        { label: getFormattedMessage('type', intl), key: 'type' },
        { label: getFormattedMessage('tags', intl), key: 'tags' },
        { label: getFormattedMessage('devices', intl), key: 'devices' },
    ];

    const data = getDataForCsvLink(props.assets, availableTags, devices);

    return (
        <CSVLink className="btn btn-primary btn-icon-only" filename={'RIO-Assets.csv'} data={data} headers={headers}>
            <span className={'rioglyph rioglyph-download'} />
        </CSVLink>
    );
};
